<template>
  <section id="editarCategoria">
    <b-tabs
      v-if="mostrarDatos"
      pills
      lazy
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>{{ $t("contents.categoryData") }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          :show="showData"
        >
          <b-form @submit="updateCategoryData">
            <b-card>
              <b-card-header>
                <b-card-title>{{ $t("contents.categoryData") }}</b-card-title>
                <b-button
                  variant="success"
                  type="submit"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </b-card-header>
              <b-card-body>
                <b-form-row class="align-items-center">
                  <b-col>
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('contents.categoryName') + (language == base ? ' *' : '')"
                    >
                      <b-form-input
                        v-model="categoryName"
                        :placeholder="$t('firstName')"
                        maxlength="150"
                        class="mr-1"
                        minlength="3"
                        :required="language == base"
                      />
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group :label="$t('category.shortDescription') + (language == base ? ' *' : '')">
                      <b-form-input
                        id="shortDescription"
                        v-model="shortDescription"
                        maxlength="180"
                        :placeholder="$t('category.shortDescription')"
                        :required="language == base"
                      />
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('contents.categoryDescription')"
                    >
                      <b-form-textarea
                        v-model="categoryDescription"
                        class="mr-1"
                        :placeholder="$t('dataGeneric.description')"
                      />
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                      v-if="!specialCategory(typeCategoryFromDb)"
                      class="mb-2 mr-1"
                      :label="$t('category.factSheet')"
                    >
                      <b-form-textarea
                        v-model="technicalDetails"
                        class="mr-1"
                        :placeholder="$t('category.factSheet')"
                      />
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row v-if="language == base && (typeCategoryFromDb == 'POD' || typeCategoryFromDb == 'AUL')">
                  <b-col>
                    <b-form-group
                      class="mb-2 mr-1 "
                      :label="$t('obtenerXML')"
                    >
                      <div class="d-flex">
                        <b-form-input
                          v-model="feedPodcast"
                          disabled
                        />
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          v-clipboard:copy="feedPodcast"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError"
                          variant="primary"
                          class="ml-2"
                        >
                          {{ $t("dataGeneric.copy") }}
                        </b-button>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row v-if="language == base">
                  <b-col md="6">
                    <b-form-group class="mb-2 mr-1">
                      <label>{{ $t('contents.categoryType') }}
                        <strong style="color: aquamarine;">{{ finalInfo.length >
                          0 ? '*' : '' }}</strong></label>
                      <h6
                        v-if="specialCategory(typeCategoryFromDb) || (typeCategoryFromDb == 'LIN' && linkCategories.totalCount > 0)
                          ? true
                          : false"
                      >
                        {{ $t(categoryTypes[typeCategorySelected]) }}
                      </h6>
                      <b-form-select
                        v-else
                        :key="update"
                        v-model="typeCategorySelected"
                        :disabled="finalInfo.length > 0 && (typeCategoryFromDb == 'LIN')"
                        class="mr-1"
                        @change="changeFinal()"
                      >
                        <b-form-select-option
                          v-for="typ in Object.keys(categoryTypes)"
                          :key="typ"
                          :disabled="disabledOptions(typ)"
                          :value="typ"
                        >
                          {{ $t(categoryTypes[typ]) }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('contents.template')"
                    >
                      <b-form-select
                        v-model="templateCategorySelected"
                        :options="templateCategory"
                        class="mr-1"
                      />
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row v-if="language == base">
                  <b-col>
                    <div class="final-info">
                      <p>{{ finalInfo }}</p>
                    </div>
                  </b-col>
                </b-form-row>
                <b-form-row v-if="language == base && !specialCategory(typeCategoryFromDb)">
                  <b-col>
                    <b-form-group
                      :label="$t('epg.genre')"
                      label-for="genre"
                    >
                      <b-form-select
                        id="genre"
                        v-model="selectedGenre"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                        "
                        :options="allGenre"
                        label="text"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      :label="$t('epg.ageClasific')"
                      label-for="clasification"
                    >
                      <b-form-select
                        id="clasification"
                        v-model="selectedClasification"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                        "
                      >
                        <b-form-select-option :value="null">
                          {{ $t('epg.ageClasific') }}
                        </b-form-select-option>
                        <b-form-select-option
                          v-for="s in allClasification"
                          :key="s.node.id"
                          :value="s.node.id"
                        >
                          <strong :style="'color:' + s.node.color + ';'">
                            {{ s.node.initial }} -
                            {{ s.node.description }}
                          </strong>
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row v-if="language == base">
                  <b-col>
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('contents.datePublishing')"
                    >
                      <flat-pickr
                        id="example-datepicker"
                        v-model="emisionDate"
                        class="form-control"
                        :placeholder="$t('contents.datePublishing')"
                        :config="{
                          enableTime: true,
                          enableSeconds: true,
                          dateFormat: 'Y-m-d H:i:S'
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('durationCat')">
                      <b-form-input
                        id="duration"
                        v-model="duration"
                        autocomplete="new-password"
                        type="number"
                        step="any"
                        :placeholder="$t('durationCat')"
                      />
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row class="align-items-center">
                  <b-col md="11">
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('tagsCat')"
                    >
                      <v-select
                        v-model="selectedTag"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        taggable
                        push-tags
                        :placeholder="$t('addTagsC')"
                        @input="modalTags()"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <feather-icon
                      v-b-tooltip.hover.top.v-primary="$t('addTags')
                      "
                      icon="InfoIcon"
                    />
                  </b-col>
                </b-form-row>
                <b-form-row v-if="language == base">
                  <b-col>
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('badgCat')"
                    >
                      <b-form-select
                        v-model="categoryBadge"
                        class="mr-1"
                      >
                        <b-form-select-option :value="null">
                          {{ $t('noBadge') }}
                        </b-form-select-option>
                        <b-form-select-option
                          v-for="ba in badges"
                          :key="ba.value"
                          :value="ba.value"
                        >
                          {{ ba.text }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row v-if="language == base && (typeCategoryFromDb == 'POD' || typeCategoryFromDb == 'AUL')">
                  <b-col>
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('appleCategory')"
                    >
                      <b-form-select
                        v-model="appleCategory"
                        class="mr-1"
                      >
                        <b-form-select-option
                          v-for="apple in appleCategoryTypes"
                          :key="apple.key"
                          :value="apple.key"
                        >
                          {{ apple.value }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row v-if="language == base">
                  <b-col lg="3">
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('contents.externalRefCategory')"
                    >
                      <b-form-input
                        v-model="externalReferenceCategory"
                        class="mr-1"
                        maxlength="150"
                        value="externalReferenceCategory"
                        :placeholder="$t('externalRef')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="!specialCategory(typeCategoryFromDb)"
                    lg="3"
                  >
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('qualityCat')"
                    >
                      <b-form-select
                        v-model="quality"
                        class="mr-1"
                      >
                        <b-form-select-option
                          v-for="typ in Object.keys(qualityOptions)"
                          :key="typ"
                          :value="typ"
                        >
                          {{ qualityOptions[typ] }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="!specialCategory(typeCategoryFromDb)"
                    class="d-flex ml-2"
                  >
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('contents.finalCategory')"
                    >
                      <b-form-checkbox
                        id="finalCategory"
                        v-model="finalCategory"
                        :disabled="!disabledFinalOption() &&
                          canChangeFinal
                          ? false
                          : true
                        "
                        name="finalCategory"
                        :switch="true"
                      >
                        Final
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="!specialCategory(typeCategoryFromDb)">
                    <b-form-group
                      v-if="subCategory && finalCategory"
                      class="mb-2 mr-1"
                      :label="$t('category.paidCategory')"
                    >
                      <b-form-checkbox
                        id="paymentCategory"
                        v-model="isPaymentCategory"
                        name="isPaymentCategory"
                        :switch="true"
                      >
                        {{ $t('category.payCategory') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="!specialCategory(typeCategoryFromDb) && (typeCategoryFromDb != 'POD' || typeCategoryFromDb != 'AUL')"
                    class="d-flex ml-2"
                  >
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('hasSub')"
                    >
                      <b-form-checkbox
                        id="hasSubtitle"
                        v-model="hasSubtitle"
                        name="hasSubtitle"
                        :switch="true"
                      >
                        {{ $t('subt') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="typeCategorySelected == 'FIL'
                    "
                    class="d-flex ml-2"
                  >
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('grid')"
                    >
                      <b-form-checkbox
                        id="modeGrid"
                        v-model="modeGrid"
                        name="modeGrid"
                        :switch="true"
                      >
                        Grid
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row v-if="language == base">
                  <b-col
                    v-if="!specialCategory(typeCategoryFromDb) && typeCategoryFromDb != 'LIN'"
                    lg="4"
                    md="12"
                  >
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('durIntro')"
                    >
                      <b-form-input
                        id="startSecondChapter"
                        v-model="startSecondChapter"
                        type="number"
                        :min="0"
                        :placeholder="$t('durIntro')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="!specialCategory(typeCategoryFromDb)
                      &&
                      typeCategoryFromDb != 'LIN'"
                    lg="4"
                    md="12"
                  >
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('durCred')"
                    >
                      <b-form-input
                        id="finishSecondChapter"
                        v-model="finishSecondChapter"
                        type="number"
                        :min="0"
                        :placeholder="$t('durCred')"
                      />
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </b-card-body>
            </b-card>
          </b-form>
        </b-overlay>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FilmIcon" />
          <span>{{ $t("contents.categoryFiles") }}</span>
        </template>

        <b-overlay
          variant="transparent"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          :show="showFiles"
        >
          <b-card>
            <b-card-header>
              <b-card-title>
                {{
                  $t("contents.categoryFiles")
                }}
              </b-card-title>
              <div>
                <b-button
                  v-if="mediaOriginSelectedDB === 'DIR'"
                  variant="primary"
                  class="mr-2"
                  @click="goToInternal"
                >
                  {{ $t("pasarInterno") }}
                </b-button>
                <b-button
                  variant="success"
                  @click="updateCategoryFiles"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </div>
            </b-card-header>
            <b-row>
              <b-col
                lg="11"
                class="ml-2 mb-2"
              >
                <b-form>
                  <!-- form input -->
                  <b-form-group
                    v-if="language == base"
                    class="mb-2 mr-1"
                    :label="$t('resources.originMedia')"
                  >
                    <b-form-select
                      v-model="mediaOriginSelected"
                      class="mr-1"
                      :options="mediaOriginOptions"
                    />
                  </b-form-group>
                  <!-- SUBIDA DE ARCHIVOS -->
                  <div>
                    <!-- FILA TV/WEB -->
                    <b-form-group class="m-1">
                      <label><strong>TV/Web</strong></label>
                      <b-row style="justify-content: space-around;;">
                        <!-- TARJETA -->
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? imageCategorySelected : imageCategoryUrlSelected"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'imageCategory' + language"
                          :label="$t('contents.imgInternal')"
                          :can-delete="true"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                        <!-- FONDO NORMAL -->
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? backgroundCategorySelected : backgroundCategoryURL"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'backgroundCategory' + language"
                          :label-info="$t('infoImage.background')"
                          :label="$t('contents.bckgInternal') + ' ' + $t('precontent')"
                          :can-delete="true"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                        <!-- FONDO TYPE BCK -->
                        <image-element-vue
                          v-if="templateCategorySelected == 'BCK' || templateCategorySelected == 'INF'"
                          :image-origin="mediaOriginSelected == 'BUK' ? backgroundRootSelected : backgroundRootUrl"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'backgroundRoot' + language"
                          :label="$t('backList')"
                          :can-delete="true"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                        <b-col
                          v-else
                          cols="3"
                        />
                      </b-row>
                    </b-form-group>
                    <!-- FILA MÓVIL -->
                    <b-form-group class="m-1">
                      <label><strong>{{ $t('Movil') }}</strong></label>
                      <b-row style="justify-content: space-around;;">
                        <!-- TARJETA MÓVIL -->
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? mobileImageCategorySelected : mobileImageCategoryURL"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'mobileImageCategory' + language"
                          :label="$t('contents.mobileImgInternal')"
                          :can-delete="true"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                        <!-- FONDO MÓVIL NORMAL -->
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? backgroundMobileCategorySelected : backgroundMobileCategoryURL"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'backgroundMobileCategory' + language"
                          :label="$t('backPre')"
                          :label-info="$t('infoImage.backgroundMobile')"
                          :can-delete="true"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                        <!-- FONDO MÓVIL BCK -->
                        <image-element-vue
                          v-if="templateCategorySelected == 'BCK' || templateCategorySelected == 'INF'"
                          :image-origin="mediaOriginSelected == 'BUK' ? backgroundRootMobileSelected : backgroundRootMobileUrl"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'backgroundRootMobile' + language"
                          :label="$t('backListMov')"
                          :can-delete="true"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                        <b-col
                          v-else
                          cols="3"
                        />
                      </b-row>
                    </b-form-group>
                    <!-- FILA RECURSOS VARIOS -->
                    <b-form-group class="m-1">
                      <label><strong>{{ $t('otherRes') }}</strong></label>
                      <b-row style="justify-content: space-around;;">
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? altImageCategorySelected : altImageCategoryURL"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'altImageCategory' + language"
                          :label="$t('editContent.image')"
                          :label-info="$t('infoImage.alternative')"
                          :can-delete="true"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? titleImageCategorySelected : titleImageCategoryURL"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'titleImageCategory' + language"
                          :label="$t('logoTit')"
                          :label-info="$t('infoImage.title')"
                          :can-delete="true"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? trailerCategorySelected : trailerCategoryURL"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'trailerCategory' + language"
                          :label="$t('contents.trailerInterno')"
                          :can-delete="true"
                          :video="true"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                      </b-row>
                    </b-form-group>
                    <!-- FILA IMAGEN MÁS RECURSOS -->
                    <b-form-group class="m-1">
                      <b-row style="justify-content: space-around;;">
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? sponsorImageSelected : sponsorImageUrl"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'sponsorImage' + language"
                          :label="$t('sponsorImage')"
                          :label-info="$t('infoImage.title')"
                          :can-delete="true"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                        <image-element-vue
                          v-if="typeCategoryFromDb == 'POD' || typeCategoryFromDb == 'AUL'"
                          :image-origin="mediaOriginSelected == 'BUK' ? imagePodcastSelected : imagePodcastUrl"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'imagePodcast' + language"
                          :label="$t('POD')"
                          :label-info="$t('infoImage.podcast')"
                          :can-delete="true"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                        <b-col
                          v-else
                          cols="3"
                        />
                        <b-col cols="3" />
                      </b-row>
                    </b-form-group>
                  </div>
                </b-form>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-tab>
      <!-- SEO -->
      <b-tab>
        <template #title>
          <feather-icon icon="Edit2Icon" />
          <span>SEO</span>
        </template>
        <b-overlay
          variant="transparent"
          :show="showSeo"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-card>
            <b-card-header>
              <b-card-title>SEO</b-card-title>
              <b-button
                variant="success"
                @click="updateSeo"
              >
                {{
                  $t("dataGeneric.save")
                }}
              </b-button>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col
                  lg="12"
                  class="ml-2 mb-2"
                >
                  <b-form>
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('title')"
                    >
                      <b-form-input
                        v-model="seoTitle"
                        :placeholder="$t('title')"
                        maxlength="80"
                        class="mr-1"
                      />
                    </b-form-group>

                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('dataGeneric.description')"
                    >
                      <b-form-input
                        v-model="seoDescription"
                        :placeholder="$t('dataGeneric.description')"
                        class="mr-1"
                        maxlength="180"
                      />
                    </b-form-group>
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('friendUrl')"
                    >
                      <b-form-input
                        v-model="friendlyUrl"
                        :placeholder="$t('friendUrl')"
                        maxlength="255"
                        class="mr-1"
                      />
                    </b-form-group>

                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('converFriendUrl') + ':'"
                    >
                      {{ friendlyUrlValue }}
                    </b-form-group>
                  </b-form>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-tab>
      <!-- LINK &CATEGORIES -->
      <b-tab v-if="language == base">
        <template #title>
          <feather-icon icon="LinkIcon" />
          <span>{{ $t('Categorias') }}</span>
        </template>
        <b-card>
          <b-card-header>
            <b-card-title>{{ $t('category.parent') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-list-group>
              <b-list-group-item
                class="d-flex align-items-center"
                :to="subCategory ? { name: 'contents-subcategories', params: { id: parentCategory.id } } : { name: 'contents-categories' }"
              >
                <div>
                  <h5>
                    <!-- <b-link
                    > -->
                    <!-- target="_blank" -->
                    {{ subCategory ? parentCategory.name : $t('contents.start') }}
                    <!-- </b-link> -->
                  </h5>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>
        <b-card v-if="link.totalCount > 0">
          <b-card-header>
            <b-card-title>{{ $t('playlist') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-list-group class="scroll-list">
              <b-list-group-item
                v-for=" i in link.edges "
                :key="i.node.id"
                disabled
                class="d-flex align-items-center"
              >
                <div>
                  <h4>{{ i.node.parentCategory.name }}</h4>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>
      </b-tab>
      <!-- SEGMENTATION -->
      <b-tab v-if="language == base && showSegmentation">
        <template #title>
          <feather-icon icon="TvIcon" />
          <span>{{ $t("segmentation") }}</span>
        </template>
        <b-card>
          <b-card-header>
            <b-card-title class="d-flex">
              {{ $t("segmDevices") }}
              <b-form-checkbox
                v-model="selectAll"
                class="ml-2"
                switch
                @change="clkSelectAll"
              >
                {{ $t('slider.allSelect') }}
              </b-form-checkbox>
            </b-card-title>

            <b-button
              variant="success"
              @click="updateSegmentationCategory"
            >
              {{
                $t("dataGeneric.save")
              }}
            </b-button>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col class="m-1">
                <b-form-checkbox
                  v-model="targetWebActive"
                  switch
                >
                  Web
                </b-form-checkbox>
              </b-col>
              <b-col class="m-1">
                <b-form-checkbox
                  v-model="targetHbbtvActive"
                  switch
                >
                  HbbTv
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="m-1">
                <b-form-checkbox
                  v-model="targetMobileActive"
                  switch
                >
                  {{ $t('Movil') }}
                </b-form-checkbox>
              </b-col>
              <b-col class="m-1">
                <b-form-checkbox
                  v-model="targetSmarttvActive"
                  switch
                >
                  SmartTv
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-tab>
      <!-- Estado -->
      <b-tab v-if="language == base">
        <template #title>
          <feather-icon icon="CalendarIcon" />
          <span>{{ $t("editContent.status") }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          :show="showState"
        >
          <b-card>
            <b-card-header>
              <b-card-title>{{ $t("editContent.status") }}</b-card-title>
              <b-button
                variant="success"
                @click="updateStateCategory"
              >
                {{
                  $t("dataGeneric.save")
                }}
              </b-button>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col
                  lg="12"
                  class="ml-2 mb-2"
                >
                  <b-form>
                    <b-form-group :label="$t('message.tableHeader.status')">
                      <b-form-select
                        id="categoryStatus"
                        v-model="categoryStatus"
                        :label="$t('message.tableHeader.status')"
                      >
                        <b-form-select-option
                          v-for=" s in status "
                          :key="s.value"
                          :value="s.value"
                        >
                          {{ s.text }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-form>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-tab>
      <b-tab v-if="language == base">
        <template #title>
          <feather-icon icon="EditIcon" />
          <span>{{ $t("contents.categoryDesign") }}</span>
        </template>
        <!-- DISEÑO DE LAS TARJETAS -->
        <b-row
          v-if="!specialCategory(typeCategoryFromDb)"
          class="match-height mt-2"
        >
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showDesing"
              :opacity="1.00"
              :blur="'2px'"
              rounded="lg"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>{{ $t('designCards') }}</b-card-title>
                  <b-button
                    class="mt-2"
                    variant="success"
                    @click="updateDesignCategory($event, 0)"
                  >
                    {{ $t("dataGeneric.save") }}
                  </b-button>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col
                      lg="12"
                      class="ml-2 mb-2"
                    >
                      <b-form>
                        <!-- form input -->
                        <b-row>
                          <b-col md="5">
                            <label class="d-flex">
                              <span>{{ $t("contents.categoryCard") }}</span>
                              <feather-icon
                                v-if="cardSelected != null"
                                class="text-danger cursor-pointer ml-2"
                                icon="XOctagonIcon"
                                size="15"
                                @click="deleteCard('cardTV')
                                "
                              />
                            </label>
                            <b-form-group
                              class="
                                            mt-2
                                          mb-2
                                          mr-1
                                          embed-responsive embed-responsive-16by9
                                        "
                            >
                              <div
                                id="cardTV"
                                class="base-card-input embed-responsive-item"
                                @click="showModalCard('cardTV')"
                              >
                                <span
                                  class="placeholder"
                                  :style="cardSelected != null
                                    ? `background: url('${cardSelected.sampleImage}')center/contain no-repeat;`
                                    : ''
                                  "
                                >
                                  <feather-icon
                                    v-if="cardSelected == null"
                                    icon="LayoutIcon"
                                    size="80"
                                  />
                                </span>
                              </div>
                            </b-form-group>
                            <span>
                              {{
                                cardSelected != null
                                  ? cardSelected.name
                                  : $t('noSelect')
                              }}</span>
                          </b-col>
                          <b-col md="5">
                            <label class="d-flex ">
                              <span>
                                {{ $t("contents.smartphoneCategoryCard") }}</span>
                              <feather-icon
                                v-if="cardMobileSelected != null"
                                class="text-danger cursor-pointer ml-2"
                                icon="XOctagonIcon"
                                size="15"
                                @click="
                                  deleteCard('cardMobile')
                                "
                              />
                            </label>

                            <b-form-group
                              class="
                                    mt-2
                                  mb-2
                                  mr-1
                                  embed-responsive embed-responsive-16by9
                                "
                            >
                              <div
                                id="cardMobile"
                                class="base-card-input embed-responsive-item"
                                @click="showModalCard('cardMobile')"
                              >
                                <span
                                  :style="cardMobileSelected != null
                                    ? `background: url('${cardMobileSelected.sampleImage}')center/contain no-repeat;`
                                    : ''
                                  "
                                  class="placeholder"
                                >
                                  <feather-icon
                                    v-if="cardMobileSelected == null"
                                    icon="LayoutIcon"
                                    size="80"
                                  />
                                </span>
                              </div>
                            </b-form-group>
                            <span>
                              {{
                                cardMobileSelected != null
                                  ? cardMobileSelected.name
                                  : $t('noSelect')
                              }}</span>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
        <!-- DISEÑO TITULOS -->
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :opacity="1.00"
              :blur="'2px'"
              rounded="lg"
              :show="showDesingTit"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>{{ $t('desginText') }}</b-card-title>
                  <b-button
                    variant="success"
                    @click="updateDesignCategory($event, 1)"
                  >
                    {{ $t("dataGeneric.save") }}
                  </b-button>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col
                      lg="12"
                      class="ml-2 mb-2"
                    >
                      <b-form>
                        <b-row>
                          <b-col>
                            <b-form-group label-for="contentDesign">
                              <b-form-select
                                id="contentDesign"
                                v-model="contentDesign"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="contentDesignTypes"
                                label="text"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('catTit')"
                            >
                              <b-form-checkbox
                                id="isTitle"
                                v-model="isTitle"
                                name="isTitle"
                                :switch="true"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab v-if="language == base && !specialCategory(typeCategoryFromDb) && typeCategoryFromDb !== 'LIN'">
        <template #title>
          <feather-icon icon="LoaderIcon" />
          <span>{{ $t('Automatizaciones') }}</span>
        </template>
        <automation-category
          :final="finalCategory"
          :code="id"
          :response="response"
        />
      </b-tab>
      <b-tab
        v-if="language == base && specialCategory(typeCategoryFromDb) &&
          typeCategoryFromDb != 'FAV' &&
          typeCategoryFromDb != 'COW'
        "
      >
        <template #title>
          <feather-icon icon="SlashIcon" />
          <span>{{ $t('restCat') }}</span>
        </template>
        <b-card>
          <b-card-header>
            <b-card-title>{{ $t('restCat') }}</b-card-title>
            <b-button
              variant="success"
              @click="updateCategoryRestriction($event)"
            >
              {{ $t("dataGeneric.save") }}
            </b-button>
          </b-card-header>
          <b-form @submit.prevent>
            <b-form-group class="mb-2 mr-1">
              <b-row class="mb-2 mt-2">
                <!-- people group 1 -->
                <b-col md="6">
                  <b-row>
                    <b-col class="ml-2">
                      <h6 class="text-primary font-weight-bold mb-1">
                        {{ $t("code.allC") }}
                      </h6>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      md="5"
                      class="ml-2"
                    >
                      <b-form-input
                        v-model="search"
                        :placeholder="$t('slider.searchThreeCaracCat')"
                        @input="getCategoriesThreeCharact"
                      />
                    </b-col>
                    <b-col
                      md="5"
                      class="d-flex align-items-center"
                    >
                      <feather-icon
                        v-show="resCategories.length > 0"
                        icon="PlusCircleIcon"
                        size="25"
                        class="text-success cursor-pointer ml-1"
                        @click="AddCat()"
                      />
                    </b-col>
                  </b-row>
                  <b-form-select
                    v-model="checkResults"
                    tag="ul"
                    multiple
                    :select-size="5"
                    class="scroll-list list-group list-group-flush  mt-2 border-0"
                  >
                    <b-form-select-option
                      v-for=" c in resCategories "
                      :key="c.node.id"
                      tag="li"
                      :value="c"
                      class="list-group-item"
                    >
                      <div class="d-flex ">
                        <div class="ml-25 ">
                          <b-card-text class="mb-0 font-weight-bold">
                            {{ c.node.name }}
                          </b-card-text>
                        </div>
                      </div>
                    </b-form-select-option>
                    <b-form-select-option
                      v-if="resCategories.length == 0"
                      :value="null"
                      disabled
                      class="list-group-item"
                    >
                      <div class="d-flex ">
                        <div
                          v-if="search.length <= 2"
                          class="ml-25 "
                        >
                          <b-card-text class="mb-0 font-weight-bold">
                            {{ threeCharSearch }}
                          </b-card-text>
                        </div>
                        <div
                          v-if="search.length >= 3"
                          class="text-left mt-2 ml-2"
                        >
                          <b-card-text class="mb-0 font-weight-bold">
                            {{ $t('subscriptions.notFound') }}
                          </b-card-text>
                        </div>
                      </div>
                    </b-form-select-option>
                  </b-form-select>
                </b-col>
                <!--categorías restringidas -->
                <b-col md="6">
                  <b-row>
                    <b-col class="ml-2">
                      <h6 class="text-primary font-weight-bold mb-1">
                        {{ $t('restrict') }}
                      </h6>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      md="5"
                      class="ml-2"
                    >
                      <b-form-input
                        v-model="searchSelected"
                        :placeholder="$t('slider.searchThreeCaracCat')"
                        @input="getCategoriesSelected"
                      />
                    </b-col>
                    <b-col
                      md="4"
                      class="d-flex align-items-center"
                    >
                      <feather-icon
                        v-show="resultCategorySelected.length > 0"
                        icon="XCircleIcon"
                        size="25"
                        class="text-danger cursor-pointer ml-1"
                        @click="QuitarCat()"
                      />
                    </b-col>
                  </b-row>

                  <b-form-select
                    v-model="checkRemove"
                    tag="ul"
                    multiple
                    :select-size="5"
                    class="scroll-list list-group list-group-flush mt-2 border-0"
                  >
                    <b-form-select-option
                      v-for=" catSus in resultCategorySelected "
                      :key="catSus.node.id"
                      tag="li"
                      :value="catSus"
                      class="list-group-item"
                    >
                      <div class="d-flex ">
                        <div class="ml-25 ">
                          <b-card-text class="mb-0 font-weight-bold">
                            {{ catSus.node.name }}
                          </b-card-text>
                        </div>
                      </div>
                    </b-form-select-option>
                  </b-form-select>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
        </b-card>
      </b-tab>
      <b-tab
        v-if="language == base"
        lazy
      >
        <template #title>
          <feather-icon icon="MonitorIcon" />
          <span>Slider</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showFiles"
              :opacity="1.00"
              :blur="'2px'"
              rounded="lg"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>
                    {{ $t('sliderCat') }}
                  </b-card-title>
                  <b-button
                    variant="success"
                    @click="updateSlider"
                  >
                    {{ $t("dataGeneric.save") }}
                  </b-button>
                </b-card-header>
                <b-form class="p-1">
                  <b-form-row class="align-items-center mb-2">
                    <b-col md="11">
                      <b-form-group>
                        <label>{{ $t('sliderCat') }}
                          <feather-icon
                            icon="XCircleIcon"
                            size="18"
                            class="text-danger cursor-pointer ml-1"
                            @click="emptySlider()"
                          /></label>
                        <div @click="$refs['slider-modal'].show()">
                          <b-form-input
                            id="slider"
                            v-model="sliderName"
                            autocomplete="new-password"
                            :placeholder="$t('sliderCat')"
                            disabled
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="1"
                      class="align-items-center"
                      style="margin-top: 10px;"
                    >
                      <b-button
                        variant="info"
                        @click="$refs['slider-modal'].show()"
                      >
                        {{ $t('Select') }}
                      </b-button>
                    </b-col>
                  </b-form-row>
                  <b-form-row
                    v-if="slider.length > 0"
                    class="p-1"
                  >
                    <b-col>
                      <b-list-group>
                        <b-list-group-item
                          v-for=" element in slider[0].node.slide.edges "
                          :key="element.node.id"
                          tag="b-row"
                          class="d-flex"
                        >
                          <b-col>
                            <b-aspect :aspect="3.34">
                              <b-img
                                fluid
                                :src="buildImageUrl(element.node)"
                              />
                            </b-aspect>
                          </b-col>
                          <b-col class="d-flex justify-content-between align-items-center">
                            <div class="m-1 align-items-center">
                              <h3
                                :class="element.node.isActive ? 'text-success' : 'text-danger'
                                "
                              >
                                {{ element.node.name }}
                              </h3>
                              <strong>Slide {{ type[element.node.slideType] }}</strong>
                            </div>
                          </b-col>
                        </b-list-group-item>
                      </b-list-group>
                    </b-col>
                  </b-form-row>
                </b-form>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab v-if="geoipbloking && language == base">
        <template #title>
          <feather-icon icon="MapIcon" />
          <span>{{ $t('zones.title') }}</span>
        </template>
        <b-card>
          <geoblocking :type-element="'CAT'" />
        </b-card>
      </b-tab>
      <b-tab v-if="superUser && language == base">
        <template #title>
          <feather-icon icon="UserCheckIcon" />
          <span>{{ $t('verification') }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          :show="showVerificator"
        >
          <b-card>
            <b-card-header>
              <b-card-title>{{ $t('verification') }}</b-card-title>
              <b-button
                variant="success"
                @click="updateVerificator"
              >
                {{
                  $t("dataGeneric.save")
                }}
              </b-button>
            </b-card-header>
            <b-card-body>
              <b-row class="align-items-center">
                <b-col md="10">
                  <b-form>
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('conectores')"
                    >
                      <b-form-tags
                        v-model="vuserVerificators"
                        :placeholder="$t('conectores')"
                      >
                        <template
                          #default="{
                            tags,
                            removeTag
                          }"
                        >
                          <ul
                            v-if="tags.length > 0"
                            class="list-inline d-inline-block mb-1"
                          >
                            <li
                              v-for="tag in tags"
                              :key="JSON.parse(tag).node.id"
                              class="list-inline-item"
                            >
                              <b-form-tag
                                :title="JSON.parse(tag).node.name"
                                variant="primary"
                                @remove="removeTag(tag)"
                              >
                                {{ JSON.parse(tag).node.name }}
                              </b-form-tag>
                            </li>
                          </ul>
                        </template>
                      </b-form-tags>
                    </b-form-group>
                  </b-form>
                </b-col>
                <b-col md="2">
                  <b-button
                    variant="primary"
                    @click="$refs.verificator.show()"
                  >
                    {{ $t('purchases.selectCategory') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-tab>
    </b-tabs>
    <b-modal
      ref="verificator"
      size="xl"
      hide-footer
      :title="$t('conectorVerificator')"
    >
      <div class="d-block text-center">
        <verificator-selector @data="setVerificator" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="$refs.verificator.hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="modalCard"
      size="xl"
      hide-footer
      :title="$t('modal.card')"
    >
      <div class="d-block text-center">
        <card-selector-modal
          :seleccionado="idSelectCard"
          @id="SelectCardId"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="slider-modal"
      size="xl"
      hide-footer
      :title="'Sliders'"
    >
      <div class="d-block text-center">
        <slider-selector-modal @sliderData="getSliderData" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import 'videojs-markers'
import videojs from 'video.js'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import {
  specialCategory, isEmpty, showToast, deactivateMenu, deactivateSlider, base64Encode, messageError, camelToSnake, limitCharacter,
} from '@/store/functions'
import vSelect from 'vue-select'
import appleCategories from '@core/utils/appleCategories'

import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  VBTooltip,
  BAspect,
  BListGroup,
  BFormTags,
  BListGroupItem,
  BFormTag,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormRow,
  BFormSelectOption,
  BImg,
  BFormTextarea,
  BCardText,
  BOverlay,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import configCategoryType from '@core/utils/categoryType'
import { getUserData } from '@/auth/utils'
import * as constants from '@core/utils/constants'
import { getQualityOptions } from '@/@core/utils/utils'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import ImageElementVue from '@/views/components/ImageElement.vue'
import geoblocking from '@/views/components/geoblocking.vue'
import CardSelectorModal from '@/views/common/CardSelectorModal.vue'
import SliderSelectorModal from '@/views/common/SliderSelectorModal.vue'
import VerificatorSelector from '@/views/common/VerificatorSelector.vue'
import automationCategory from '../automationCategory.vue'

const noCover = require('@/assets/images/backend/nocover.jpeg')

const headers = {
  'Accept-Language': 'es',
  'Content-Language': 'es',
}
export default {
  components: {
    BAspect,
    BTabs,
    BFormTag,
    BTab,
    BCardText,
    BImg,
    VerificatorSelector,
    BCardHeader,
    SliderSelectorModal,
    BCardTitle,
    BCardBody,
    BFormTags,
    BRow,
    BCol,
    BCard,
    vSelect,
    BForm,
    BFormRow,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    ImageElementVue,
    BButton,
    BOverlay,
    flatPickr,
    automationCategory,
    BListGroup,
    BListGroupItem,
    // Form Validation
    CardSelectorModal,
    BFormSelectOption,
    geoblocking,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      required,
      categoryStatus: 'RED',
      vuserVerificators: {},
      superUser: false,
      parentCategory: {},
      status: [
        { value: 'RED', text: `🔴 ${this.$t('code.desactivado')}` },
        { value: 'YEW', text: `🟡 ${this.$t('dataGeneric.internal')}` },
        { value: 'GRE', text: `🟢 ${this.$t('ecommerce.public')}` },
      ],
      viewName: this.$t('contents.editCategoryTitle'),
      viewDetail: this.$t('contents.editCategorySub'),
      modeGrid: false,
      quality: 'No_Quality',
      qualityOptions: getQualityOptions(),
      hasSubtitle: false,
      sliderName: '',
      sliderId: null,
      threeCharSearch: this.$t('slider.searchThreeCaracCat'),
      changeSyP: false,
      checkResults: [],
      checkRemove: [],
      badges: [],
      appleCategoryTypes: appleCategories,
      categoryBadge: null,
      appleCategory: null,
      emisionDate: null,
      duration: 0,
      subCategory: false,
      showSegmentation: false,
      specialCategory,
      search: '',
      searchSelected: '',
      resCategories: [],
      userData: getUserData(),
      isSponsor: false,
      id: this.$route.params.id,
      allClasification: [],
      allGenre: [{ value: null, text: this.$t('genereCat') }],
      selectedGenre: null,
      selectedClasification: null,
      showSeo: false,
      idSelectCard: null,
      imageCategorySrc: null,
      imageCategorySelected: null,
      backgroundRoot: null,
      backgroundRootMobile: null,
      response: null,
      mobileImageCategorySelected: null,
      typeCategoryFromDb: null,
      mobileImageCategorySrc: null,
      isActive: false,
      active: false,
      link: null,
      backgroundCategorySelected: null,
      backgroundCategorySrc: null,
      startSecondChapter: null,
      finishSecondChapter: null,
      titleImageCategorySelected: null,
      titleImageCategorySrc: null,
      categorySelected: [],
      resultCategorySelected: [],
      allCats: [],
      backgroundRootSelected: null,
      backgroundRootMobileSelected: null,
      trailerCategorySelected: null,
      backgroundRootMobileSrc: null,
      backgroundRootSrc: null,
      trailerCategorySrc: null,
      imageClicked: null,
      categoryName: '',
      categoryDescription: '',
      categoryFather: '',
      categorySubgroup: '',
      externalReferenceCategory: '',
      mediaOriginSelected: 'BUK',
      mediaOriginSelectedDB: 'BUK',
      menu: 0,
      mediaOriginOptions: [
        { value: 'BUK', text: this.$t('dataGeneric.internal') },
        { value: 'DIR', text: this.$t('dataGeneric.external') },
      ],
      selectedTag: [],
      idTags: [],
      originalTags: [],
      optionTag: [],
      tipo: '',
      video: null,
      imageCategory: null,
      imageCategorySelectedPlaceholder: '',
      backgroundRootMobilePlaceholder: '',
      backgroundRootPlaceholder: '',
      backgroundRootUrl: '',
      backgroundRootMobileUrl: '',
      imageCategoryUrlSelected: '',
      mobileImageCategory: null,
      mobileImageCategoryPlaceholder: '',
      mobileImageCategoryURL: '',
      backgroundCategory: null,
      backgroundCategoryPlaceholder: '',
      backgroundMobileCategoryPlaceholder: '',
      backgroundCategoryURL: '',
      mostrarDatos: false,
      altImageCategory: null,
      altImageCategorySrc: null,
      altImageCategoryURL: '',
      altImageCategoryPlaceholder: '',
      altImageCategorySelected: null,
      imagePodcastSelected: null,
      sponsorImageSelected: null,
      sponsorImageUrl: '',
      imagePodcast: null,
      sponsorImage: null,
      imagePodcastUrl: '',
      backgroundMobileCategoryURL: '',
      backgroundMobileCategory: null,
      titleImageCategory: null,
      titleImageCategoryPlaceholder: '',
      titleImageCategoryURL: '',
      trailerCategory: null,
      trailerCategoryPlaceholder: '',
      trailerCategoryURL: '',
      categorySuscriptions: null,
      isPaymentCategory: false,
      priceSelected: null,
      typeCategorySelected: 'NOR',
      finalCategory: false,
      seoTitle: null,
      seoDescription: null,
      canChangeFinal: null,
      defaultLanguage: '',
      finalInfo: '',
      templateCategorySelected: null,
      templateCategory: [
        { value: null, text: this.$t('designTypes.templ') },
        { value: 'LAT', text: this.$t('designTypes.side') },
        { value: 'BCK', text: 'Background' },
        { value: 'INF', text: this.$t('designTypes.backInfo') },
      ],
      categoryTypes: {},
      update: 0,
      languageSelected: null,
      platformLanguages: [{ value: null, text: 'Select a language' }],
      cardSelected: null,
      cardMobileSelected: null,
      blurEffectStatus: false,
      client: '',
      publishingDate: null,
      finishingDate: null,
      labelStatus: 'RED',
      showData: false,
      showFiles: false,
      showPayment: false,
      showContent: false,
      showDesing: false,
      friendlyUrl: null,
      friendlyUrlValue: null,
      showDesingTit: false,
      containtCategory: false,
      showLanguage: false,
      showState: false,
      showVerificator: false,
      typeCard: null,
      isTitle: false,
      backgroundMobileCategorySelected: false,
      technicalDetails: null,
      backgroundMobileCategorySrc: null,
      shortDescription: null,
      feedPodcast: '',
      contentDesign: 'NOT',
      moveSelected: [],
      categorySlide: 0,
      contentDesignTypes: [
        { value: 'NOT', text: this.$t('designTypes.not') },
        { value: 'ONL', text: this.$t('designTypes.onl') },
        { value: 'CTC', text: this.$t('designTypes.ctc') },
        { value: 'TAD', text: this.$t('designTypes.tad') },
        {
          value: 'TDS',
          text: this.$t('designTypes.tds'),
        },
        { value: 'TID', text: this.$t('designTypes.tid') },
      ],
      slider: [],
      type: {
        SHO: this.$t('Shop'),
        CON: this.$t('Contents'),
        URL: 'URL',
        CAT: this.$t('purchases.category'),
        CAP: this.$t('slider.productCategory'),
        PRO: this.$t('producto'),
      },
      targetWebActive: false,
      targetHbbtvActive: false,
      targetMobileActive: false,
      targetSmarttvActive: false,
      selectAll: false,
      geoipbloking: false,

    }
  },
  computed: {
    nameState() {
      return this.name.length > 2
    },
  },
  watch: {

    targetWebActive() {
      if (!this.targetWebActive) {
        this.selectAll = false
      }
      if (this.targetWebActive && this.targetHbbtvActive && this.targetMobileActive && this.targetSmarttvActive) this.selectAll = true
    },
    targetHbbtvActive() {
      if (!this.targetHbbtvActive) {
        this.selectAll = false
      }
      if (this.targetWebActive && this.targetHbbtvActive && this.targetMobileActive && this.targetSmarttvActive) this.selectAll = true
    },
    targetMobileActive() {
      if (!this.targetMobileActive) {
        this.selectAll = false
      }
      if (this.targetWebActive && this.targetHbbtvActive && this.targetMobileActive && this.targetSmarttvActive) this.selectAll = true
    },
    targetSmarttvActive() {
      if (!this.targetSmarttvActive) {
        this.selectAll = false
      }
      if (this.targetWebActive && this.targetHbbtvActive && this.targetMobileActive && this.targetSmarttvActive) this.selectAll = true
    },
    mediaOriginSelected() {
      this.imageClicked = null
    },
    duration() {
      function checkNumberType(value) {
        if (Number.isFinite(value)) {
          return true
        }
        return false
      }
      try {
        this.duration = checkNumberType(this.duration) ? this.duration.toFixed(2) : this.duration
      } catch (error) {
        console.log(error)
      }
    },
    finalCategory() {
      if (!this.finalCategory) {
        this.isPaymentCategory = false
      }
    },
    resCategories() {
      if (this.resCategories.length !== 0) {
        if (this.search.length <= 2) {
          this.resCategories = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracCat')
        }
      }
    },
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.search.length <= 2) {
          this.resCategories = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracCat')
        }
      }
    },
    friendlyUrl() {
      this.friendlyUrlValue = this.friendlyUrl
        // eslint-disable-next-line no-useless-escape
        .replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ')
        .toLowerCase()

      this.friendlyUrlValue = this.friendlyUrlValue.replace(/^\s+|\s+$/gm, '')

      this.friendlyUrlValue = this.friendlyUrlValue.replace(/\s+/g, '-')
    },
    imageCategory(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageCategorySelected = true
              this.imageCategorySrc = value
            })
            .catch(() => {
              this.imageCategorySrc = null
            })
        } else {
          this.imageCategorySrc = null
        }
      }
    },
    mobileImageCategory(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.mobileImageCategorySelected = true

              this.mobileImageCategorySrc = value
            })
            .catch(() => {
              this.mobileImageCategorySrc = null
            })
        } else {
          this.mobileImageCategorySrc = null
        }
      }
    },
    altImageCategory(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.altImageCategorySelected = true

              this.altImageCategorySrc = value
            })
            .catch(() => {
              this.altImageCategorySrc = null
            })
        } else {
          this.altImageCategorySrc = null
        }
      }
    },
    imagePodcast(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(() => {
              this.imagePodcastSelected = true
            })
            .catch(() => {
            })
        }
      }
    },
    backgroundMobileCategory(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.backgroundMobileCategorySelected = true

              this.backgroundMobileCategorySrc = value
            })
            .catch(() => {
              this.backgroundMobileCategorySrc = null
            })
        } else {
          this.backgroundMobileCategorySrc = null
        }
      }
    },
    backgroundCategory(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.backgroundCategorySelected = true

              this.backgroundCategorySrc = value
            })
            .catch(() => {
              this.backgroundCategorySrc = null
            })
        } else {
          this.backgroundCategorySrc = null
        }
      }
    },
    backgroundRootMobile(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.backgroundRootMobileSelected = true

              this.backgroundRootMobileSrc = value
            })
            .catch(() => {
              this.backgroundRootMobileSrc = null
            })
        } else {
          this.backgroundRootMobileSrc = null
        }
      }
    },
    backgroundRoot(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.backgroundRootSelected = true

              this.backgroundRootSrc = value
            })
            .catch(() => {
              this.backgroundRootSrc = null
            })
        } else {
          this.backgroundRootSrc = null
        }
      }
    },
    titleImageCategory(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.titleImageCategorySelected = true

              this.titleImageCategorySrc = value
            })
            .catch(() => {
              this.titleImageCategorySrc = null
            })
        } else {
          this.titleImageCategorySrc = null
        }
      }
    },
    trailerCategory(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.trailerCategorySelected = true

              this.trailerCategorySrc = value
            })
            .catch(() => {
              this.trailerCategorySrc = null
            })
        } else {
          this.trailerCategorySrc = null
        }
      }
    },

  },
  mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = this.userData.groups.edges[0].node.name === constants.sponsor
    }
    this.superUser = this.userData.isSuperuser
    this.geoipbloking = this.userData?.profile?.client?.geoipbloking

    this.getDefaultLanguage()
    this.getCategoryInfo()
    this.generateXml()
    this.clasification()
    this.genre()
    this.allBadges()
  },
  beforeDestroy() {
    if (this.video) this.video.dispose()
  },

  methods: {
    getDefaultLanguage() {
      axios
        .post('', {
          query: `
             query{
             client(id:"${this.userData.profile.client.id}"){
                 defaultLanguage
                 availableLanguages
             }
         }
         `,
        })
        .then(res => {
          this.defaultLanguage = res.data.data.client.defaultLanguage
        })
        .catch(() => {
        })
    },
    clkSelectAll() {
      this.targetWebActive = this.selectAll
      this.targetHbbtvActive = this.selectAll
      this.targetMobileActive = this.selectAll
      this.targetSmarttvActive = this.selectAll
    },
    onCopy() {
      showToast(this.$t('code.tCopy'), 1, this)
    },
    onError() {
      showToast(this.$t('code.errorCopy'), 2, this)
    },
    generateXml() {
      const mode = process.env.VUE_APP_MODE ? process.env.VUE_APP_MODE : process.env.NODE_ENV
      let host = ''
      switch (mode) {
        case 'develop':
          host = 'https://develop.interactvty.com'
          break
        case 'sandbox':
          host = 'https://sandbox.interactvty.com'
          break
        case 'production':
          host = 'https://api.interactvty.com'
          break
        default:
          host = 'http://localhost:8000'
          break
      }

      const clientName = this.userData.profile.client.name
      const idCategory = this.$route.params.id
      this.feedPodcast = `${host}/api/2.0/contents/feed_podcasts/?category_id=${idCategory}&client=${clientName}`
    },
    allBadges() {
      axios
        .post('', {
          query: `
        {
            allBadge(client:"${this.userData.profile.client.id}")
            {
            edges
            {
                node
                {
                  id
                  name
                  isActive
                  backgroundColor
                  textColor
                  image
              }
            }
          }
        }
        `,
        })
        .then(res => {
          messageError(res, this)

          this.badges = []

          res.data.data.allBadge.edges.forEach(element => {
            this.badges.push({ text: element.node.name, value: element.node.id })
          })
        })
        .catch(() => {
        })
    },
    getSliderData(data) {
      this.hideModal()
      const [id, name] = data
      this.sliderId = id
      this.sliderName = name
      this.getSlider()
    },
    disabledOptions(type) {
      const finalCategoryTypes = ['NOR', 'DIR', 'COU', 'UNI', 'MOD', 'POD', 'AUL']
      const nonFinalCategoryTypes = ['SER', 'AUL', 'PRO', 'NOR', 'FIL', 'FET', 'COU', 'UNI', 'MOD']

      if (this.finalCategory && !this.canChangeFinal) {
        // Categoría final y no se puede cambiar a final
        return !finalCategoryTypes.includes(type)
      } if (!this.finalCategory && this.containtCategory) {
        // Categoría no final y contiene categorías
        return !nonFinalCategoryTypes.includes(type)
      }

      return false
    },

    getCategoriesThreeCharact() {
      if (this.search.trim().length >= 3) {
        this.getCategories()
      }
    },
    getCategoriesSelected() {
      this.resultCategorySelected = this.categorySelected.filter(item => item.node.name.toLowerCase().includes(this.searchSelected.toLowerCase()))
      this.moveSelected = this.resultCategorySelected.slice()
    },
    deleteCard(type) {
      switch (type) {
        case 'cardTV':
          this.cardSelected = null
          break
        case 'cardMobile':
          this.cardMobileSelected = null
          break
        default:
          break
      }
    },
    getCheckClient() {
      axios
        .post('', {
          query: `
        {
          allClients(clientId:"${this.userData.profile.client.clientId}") {
            edges {
              node {
                isLinkCategories
                
                
              }
            }
          }
        }
        `,
        })
        .then(result => {
          messageError(result, this)

          this.categoryTypes = configCategoryType(false, this.subCategory, result.data.data.allClients.edges[0].node.isLinkCategories, true)

          this.update += 1
        })
        .catch(() => { })
    },
    goToInternal() {
      const header = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      this.$swal({
        title: this.$t('warning'),
        text: this.$t('internoInfo'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          cancelButton: 'btn btn-outline-danger ml-1',
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.showFiles = true

          axios
            .post('', {
              query: `
              mutation{
                categoryToInternal(categoryId:"${this.id}"){
                  success
                }
                updateCategory(id:"${this.id}",input:{mediaLocation:BUK}){
                    category{
                      id
                      mediaLocation
                    }
                  }
              }
              `,
            }, { headers: header })
            .then(res => {
              this.showFiles = false
              messageError(res, this)
              showToast(this.$t('success'), 1, this)

              this.getCategoryInfo()
            })
            .catch(() => {
              showToast(this.$t('error'), 0, this)
            })
        }
      }).catch(err => { console.log(err) })
    },
    lockedCategories(currentPage) {
      const perPage = 300
      axios
        .post('', {
          query: `
          query {
            allCategories(id:"${this.id}") {
              edges {
                node {                  
                  lockedCategories(orderBy:"name",
                  first:${perPage},
                  client:"${this.userData.profile.client.id}",
                  offset:${perPage * (currentPage - 1)}) {
                    pageInfo {                     
                      hasNextPage                      
                    }
                    totalCount
                    edgeCount
                    totalCount
                    edgeCount
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  
                }
              }            
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)

          const response = res.data.data.allCategories.edges[0].node.lockedCategories
          this.categorySelected.push(...response.edges)
          if (response.pageInfo.hasNextPage) {
            this.lockedCategories(currentPage + 1)
          } else {
            this.getCategoriesSelected()
            this.mostrarDatos = true
          }
        }).catch(err => {
          console.log(err)
        })
    },
    saveFieldSeo() {
      this.seoTitle = isEmpty(this.seoTitle) ? this.categoryName : this.seoTitle
      this.seoDescription = isEmpty(this.seoDescription) ? this.shortDescription : this.seoDescription
      this.updateSeo(false)
    },
    getCategoryInfo() {
      axios
        .post('', {
          query: `
          query {
            allCategories(id:"${this.id}") {
              edges {
                node {                                  
                    hasSubtitle
                    quality
                    sliderCategory {
                      id
                      name
                    }
                    badges {
                      edges {
                        node {
                          id
                        }
                      }
                    }
                    emisionDate
                    duration  
                    appleCategory {
                      key
                      label
                    }                
                  isActive  
                  state                
                  isTitle
                  targetWebActive
                  targetHbbtvActive
                  targetMobileActive
                  targetSmarttvActive
                  startSecondChapter
                  finishSecondChapter
                  contentDesign
                    genre {
                      id
                      name
                    }
                    clasification {
                      id
                      type
                      initial
                      description
                    }
                    lockedCategories(orderBy:"name") {                   
                      totalCount
                    }
                    linkLinkCategory {
                      totalCount
                      edges {
                        node {
                          id
                          parentCategory{
                            id
                            name
                          }
                        }
                      }
                    }     
                    linkCategories {
                      totalCount        
                    }  
                    modeGrid                                    
                    id
                           
                  parent {
                    id
                    name
                    type
                  }
                  categoryMenu {
                    totalCount
                    edges {
                      node {
                        id
                        
                      }
                    }
                  }     
                  categorySlide {
                    totalCount
                    edges {
                      node {
                        id
                      }
                    }
                  }           
                  
                  client {
                    id
                    name
                  }
                  
                  image
                  name(lang:"${this.language}")
                  technicalDetails(lang:"${this.language}")
                  description(lang:"${this.language}")
                  seoTitle(lang:"${this.language}")
                  seoDescription(lang:"${this.language}")
                  friendlyUrl(lang:"${this.language}")
                  imageUrl(lang:"${this.language}")
                  imageMobileUrl(lang:"${this.language}")
                  titleImageUrl(lang:"${this.language}")
                  trailerUrl(lang:"${this.language}")
                  backgroundUrl(lang:"${this.language}")
                  backgroundRootUrl(lang:"${this.language}")
                  backgroundRootMobileUrl(lang:"${this.language}")
                  alternativeImageUrl(lang:"${this.language}")
                  imagePodcastUrl(lang:"${this.language}")
                  sponsorImageUrl(lang:"${this.language}")
                  backgroundMobileUrl(lang:"${this.language}")
                  imageMobile
                  shortDescription(lang:"${this.language}")
                  titleImage
                  trailer
                  background
                  backgroundRoot
                  backgroundRootMobile
                  modeGrid                  
                  alternativeImage
                  backgroundMobile
                  type
                  reference
                  inapppurchase {
                    id
                  }
                  isPremium
                  isFinal
                  vuserVerificators{
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  isBackgroundBlur
                  language {
                    id
                    language
                  }
                  childCategories{
                    edges {
                      node {
                        id
                        description
                        name
                        isFinal
                        parent {
                          id
                          name
                        }
                      }
                    }
                  }
                  categoryContent(orderBy:"order,-created_at") {
                    totalCount
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  
                  card {
                    id
                    name
                    sampleImage 
                  }
                  cardMobile {
                    id
                    name
                    sampleImage
                  }
                 
                  creator {
                        id
                      }
                      sponsor {
                        edges {
                          node {
                            id
                          }
                        }
                      }
                  mediaLocation
                  templateCategory
                    tags {
                          edges {
                            node {
                              id
                              name
                            }
                          }
                        }
                      
                    }
                  }
                }

            allPurchases {
              edges {
                node {
                  id
                  name
                  price
                }
              }
            }
            allLanguages {
              edges {
                node {
                  id
                  language
                }
              }
            }
            allCards(isLegacy:false) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            allSubgroups {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)

          const response = res.data.data.allCategories.edges[0]
          if (response) {
            const { node } = response
            // Sponsor role can access every content, but cannot delete it. Uncomment it to restrict access again
            /* let authorized = false
            if (this.isSponsor) {
              node.sponsor.edges.forEach(spon => {
                if (spon.node.id === this.userData.id) {
                  authorized = true
                }
              })

              if (node.creator !== null) {
                if (node.creator.id === this.userData.id) authorized = true
              }
              if (!authorized) {
                this.$router.push({ name: 'misc-not-authorized' })
              }
            } */

            this.response = res.data.data.allCategories.edges[0].node

            this.selectedTag = []
            this.idTags = []
            this.originalTags = []
            this.response.tags.edges.forEach(element => {
              this.selectedTag.push(element.node.name)
              this.idTags.push(element.node.id)
              this.originalTags.push(element.node.name)
            })
            this.mediaOriginSelected = node.mediaLocation
            this.mediaOriginSelectedDB = node.mediaLocation
            this.templateCategorySelected = node.templateCategory
            this.vuserVerificators = node.vuserVerificators.edges
            this.targetWebActive = node.targetWebActive
            this.targetHbbtvActive = node.targetHbbtvActive
            this.targetMobileActive = node.targetMobileActive
            this.targetSmarttvActive = node.targetSmarttvActive
            if (this.targetWebActive && this.targetHbbtvActive && this.targetMobileActive && this.targetSmarttvActive) this.selectAll = true

            this.isActive = node.isActive
            this.active = node.isActive
            this.categoryStatus = node.state
            this.labelStatus = this.categoryStatus
            this.hasSubtitle = node.hasSubtitle
            this.modeGrid = node.modeGrid
            this.quality = node.quality != null ? node.quality : 'No_Quality'
            this.menu = node.categoryMenu.totalCount
            this.categorySlide = node.categorySlide.totalCount
            this.finalCategory = node.isFinal
            this.shortDescription = node.shortDescription
            this.link = node.linkLinkCategory
            this.linkCategories = node.linkCategories
            this.startSecondChapter = node.startSecondChapter
            this.appleCategory = node.appleCategory.key === '' ? null : node.appleCategory.key
            this.finishSecondChapter = node.finishSecondChapter
            const { sliderCategory } = node
            if (sliderCategory != null) {
              this.sliderName = sliderCategory.name
              this.sliderId = sliderCategory.id
            }
            if (this.sliderId) this.getSlider()

            if (node.parent != null) {
              this.subCategory = true
              this.parentCategory = node.parent
              this.showSegmentation = this.superUser
            } else {
              this.subCategory = false
              this.showSegmentation = true
            }

            if (!specialCategory(node.type)) {
              if (node.type === 'LIN' && this.linkCategories.totalCount > 0) {
                this.finalInfo = this.$t('needLink')
                this.containtCategory = true
              } else if (this.finalCategory && node.categoryContent.edges.length) {
                this.finalInfo = this.$t('needCat')
                this.canChangeFinal = false
                this.containtCategory = false
              } else if (
                !this.finalCategory
                && node.childCategories.edges.length
              ) {
                this.finalInfo = this.$t('needCatTyp')
                this.containtCategory = true
                let change = true
                node.childCategories.edges.forEach(e => {
                  if (!e.node.isFinal) {
                    change = false
                  }
                })
                this.changeSyP = change
              } else if (
                this.finalCategory
                && !node.categoryContent.edges.length
              ) {
                this.canChangeFinal = true
                this.containtCategory = false
              } else {
                this.canChangeFinal = true
                this.containtCategory = false
              }
            }

            const languagesResponseList = res.data.data.allLanguages.edges
            languagesResponseList.forEach(item => {
              this.platformLanguages.push({
                value: item.node.id,
                text: item.node.language,
              })
            })

            this.categoryName = node.name
            this.categoryDescription = node.description
            this.externalReferenceCategory = node.reference
            if (this.mediaOriginSelected === 'DIR') {
              // DIR
              this.imageCategoryUrlSelected = node.imageUrl
              this.mobileImageCategoryURL = node.imageMobileUrl
              this.backgroundCategoryURL = node.backgroundUrl
              this.backgroundRootUrl = node.backgroundRootUrl
              this.backgroundRootMobileUrl = node.backgroundRootMobileUrl
              this.altImageCategoryURL = node.alternativeImageUrl
              this.imagePodcastUrl = node.imagePodcastUrl
              this.sponsorImageUrl = node.sponsorImageUrl

              this.backgroundMobileCategoryURL = node.backgroundMobileUrl
              this.titleImageCategoryURL = node.titleImageUrl
              this.trailerCategoryURL = node.trailerUrl
            } else {
              // BUK
              this.imageCategorySelected = node.imageUrl

              this.imagePodcastSelected = node.imagePodcastUrl
              this.sponsorImageSelected = node.sponsorImageUrl
              this.altImageCategorySelected = node.alternativeImageUrl
              this.mobileImageCategorySelected = node.imageMobileUrl
              this.backgroundCategorySelected = node.backgroundUrl
              this.backgroundRootSelected = node.backgroundRootUrl
              this.backgroundRootMobileSelected = node.backgroundRootMobileUrl
              this.backgroundMobileCategorySelected = node.backgroundMobileUrl
              this.titleImageCategorySelected = node.titleImageUrl
              this.trailerCategorySelected = node.trailerUrl
            }

            this.isPaymentCategory = node.isPremium

            this.typeCategorySelected = node.type
            this.typeCategoryFromDb = node.type

            this.cardSelected = node.card ? node.card : null
            this.cardMobileSelected = node.cardMobile
              ? node.cardMobile
              : null
            this.blurEffectStatus = node.isBackgroundBlur
            this.seoTitle = node.seoTitle
            this.seoDescription = node.seoDescription
            this.friendlyUrl = node.friendlyUrl

            this.languageSelected = node.language
              ? node.language.id
              : null

            try {
              this.selectedGenre = node.genre.id
            } catch (error) {
              this.selectedGenre = null
            }
            try {
              this.selectedClasification = node.clasification.id
            } catch (error) {
              this.selectedClasification = null
            }

            this.isTitle = node.isTitle
            this.contentDesign = node.contentDesign
            this.technicalDetails = node.technicalDetails
            this.emisionDate = this.fechaFormato(node.emisionDate)
            this.duration = node.duration / 60

            if (node.badges.edges.length !== 0) {
              this.categoryBadge = node.badges.edges[0].node.id
            }
            this.getCheckClient()

            if (node.lockedCategories.totalCount !== 0) this.lockedCategories(1)
            // Sponsor role can access every content, but cannot delete it. Uncomment it to restrict access again
            // else if (!authorized && this.isSponsor) this.mostrarDatos = false
            else this.mostrarDatos = true

            this.$emit('return', {
              name: this.categoryName, viewName: this.viewName, viewDetail: this.viewDetail, typeCategory: this.typeCategoryFromDb, idCategory: this.id, state: this.labelStatus,
            })
          } else {
            this.$swal({
              title: this.$t('warning'),
              text: this.$t('sorryCat'),
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: this.$t('dataGeneric.goBack'),
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                this.$router.push({ name: 'dashboard' })
              }
            })
              .catch(err => { console.log(err) })
          }
        })
    },
    updateSlider() {
      axios
        .post('', {
          variables: {
            sliderCategory: this.sliderId,
          },
          query: `
          mutation($sliderCategory: ID) {
            updateCategory(id: "${this.id}", input: {                      
              sliderCategory: $sliderCategory,              
            }
              ){
              category{
                id
                name
                description
                reference
                isFinal
                isActive
                subgroup {
                  id
                  name
                }
                parent {
                  id
                  name
                }
              }
            }
          }
        `,
        }, { headers })
        .then(res => {
          messageError(res, this)

          showToast(
            `Categoría ${res.data.data.updateCategory.category.name} actualizada correctamente`,
            1, this,
          )
          this.showData = false
          this.getCategoryInfo()
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.showData = false
        })
    },
    updateCategoryData(event) {
      this.showData = true
      event.preventDefault()
      this.changeFinal()
      let deactive = false
      if (this.checkMenu()) {
        deactive = true
        this.$swal({
          title: this.$t('dataGeneric.atention'),
          text: this.$t('desc'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('next'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) this.dataMutation()
          else {
            this.isActive = true
            this.showData = false
          }
        }).catch(() => {

        })
      }
      if (this.checkSlider()) {
        deactive = true

        this.$swal({
          title: this.$t('dataGeneric.atention'),
          text: this.$t('desc'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('next'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) this.dataMutation()
          else {
            this.isActive = true
            this.showData = false
          }
        }).catch(() => {

        })
      }
      if (!deactive) {
        this.dataMutation()
      }
    },
    checkMenu() {
      if (this.menu !== 0 && this.active && !this.isActive) {
        return true
      }
      return false
    },
    checkSlider() {
      if (this.categorySlide !== 0 && this.active && !this.isActive) {
        return true
      }
      return false
    },

    dataMutation() {
      if (this.typeCategorySelected === 'MOS') {
        this.isActive = false
        showToast(
          this.$t('mosMess'),
          2, this,
        )
      }
      // this.saveFieldSeo()
      let objectVariables = []
      objectVariables = [
        { title: `name${this.language}`, value: limitCharacter(this.categoryName, 150), type: 'String' },
        { title: `description${this.language}`, value: this.categoryDescription, type: 'String' },
        { title: `technicalDetails${this.language}`, value: this.technicalDetails, type: 'String' },
        { title: `shortDescription${this.language}`, value: limitCharacter(this.shortDescription, 180), type: 'String' },
      ]
      if (this.language === this.base) {
        objectVariables.push(
          { title: 'reference', value: this.externalReferenceCategory, type: 'String' },
          { title: 'isActive', value: this.isActive, type: 'Boolean' },
          { title: 'isFinal', value: this.finalCategory, type: 'Boolean' },
          { title: 'hasSubtitle', value: this.hasSubtitle, type: 'Boolean' },
          { title: 'modeGrid', value: this.modeGrid, type: 'Boolean' },
          { title: 'isPremium', value: this.isPaymentCategory, type: 'Boolean' },
          { title: 'startSecondChapter', value: this.startSecondChapter, type: 'Int' },
          { title: 'finishSecondChapter', value: this.finishSecondChapter, type: 'Int' },
          { title: 'duration', value: this.duration * 60, type: 'Int' },
          { title: 'badges', value: this.categoryBadge ? [this.categoryBadge] : [], type: '[ID]' },
          { title: 'genre', value: this.selectedGenre, type: 'ID' },
          { title: 'clasification', value: this.selectedClasification, type: 'ID' },
          { title: 'sliderCategory', value: this.sliderId, type: 'ID' },
          { title: 'emisionDate', value: this.fechaFormato(this.emisionDate), type: 'DateTime' },
          { title: 'quality', value: this.quality !== 'No_Quality' ? this.quality : null, type: 'CategoryQualityInput' },
          { title: 'type', value: this.typeCategorySelected, type: 'CategoryTypeInput' },
          { title: 'templateCategory', value: this.templateCategorySelected, type: 'CategoryTemplateCategoryInput' },
          { title: 'appleCategory', value: this.appleCategory, type: 'CategoryAppleCategoryInput' },
        )
      }

      const variables = {}
      let mutation = 'mutation('
      let query = `{updateCategory(id:"${this.id}",input:{\n
        tags: [${this.idTags}]\n`
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
          category{
            id
            name
            description
            reference
            isFinal
            isActive
            subgroup {
              id
              name
            }
            parent {
              id
              name
            }
          }
        }
      }`
      query = mutation + query
      axios
        .post('', {
          variables,
          query,
        }, { headers })
        .then(res => {
          messageError(res, this)
          showToast(
            this.$t('success'),
            1, this,
          )
          this.showData = false
          this.getCategoryInfo()

          if (this.menu !== 0 && !this.isActive) {
            deactivateMenu([this.id]).then(() => {

            }).catch(err => {
              console.log(err)
            })
          }
          if (this.categorySlide !== 0 && !this.isActive) {
            deactivateSlider([this.id])
          }
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.showData = false
        })
    },
    fechaFormato(value) {
      const fecha = new Date(value)
      return value != null ? fecha : value
    },
    setVerificator(data) {
      Object.keys(data).forEach(element => {
        this.vuserVerificators.push({ node: { id: data[element].id, name: data[element].name } })
      })

      this.$refs.verificator.hide()
    },
    updateVerificator() {
      this.showVerificator = true
      const { id } = this.$route.params
      const idConnect = []
      this.vuserVerificators.forEach(element => {
        idConnect.push(JSON.parse(element).node.id)
      })
      axios
        .post('', {
          query: `mutation{
            updateCategory(id:"${id}", input:{
              vuserVerificators:[${idConnect}]
            }){
              category{
                id
                name
                state
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)
          showToast(
            this.$t('success'),
            1, this,
          )
          this.showVerificator = false
        })
        .catch(error => {
          console.log(error)
          showToast(
            this.$t('error'),
            2, this,
          )
          showToast(this.$t('code.updateDataError'), 2, this)
          this.showVerificator = false
        })
    },
    updateStateCategory(event) {
      this.showState = true
      const active = this.categoryStatus === 'GRE'
      const isActive = !!(this.categoryStatus === 'GRE' || this.categoryStatus === 'YEW')
      event.preventDefault()
      const { id } = this.$route.params
      if ((this.menu !== 0 || this.categorySlide !== 0) && !active) {
        this.$swal({
          title: this.$t('dataGeneric.atention'),
          text: this.$t('descCatAll'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('next'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            axios
              .post('', {
                query: `mutation{
            updateCategory(id:"${id}", input:{
              state: ${this.categoryStatus},
              isActive:${isActive},              
            }){
              category{
                id
                name
                state
              }
            }
          }
        `,
              })
              .then(res => {
                messageError(res, this)
                if (this.menu !== 0) {
                  deactivateMenu([this.id]).then(() => {
                  }).catch(err => {
                    console.log(err)
                  })
                }
                if (this.categorySlide !== 0) {
                  deactivateSlider([this.id])
                }

                showToast(
                  this.$t('code.cat', {
                    data: res.data.data.updateCategory.category.name,
                  }),
                  1, this,
                )

                this.getCategoryInfo()

                this.showState = false
              })
              .catch(error => {
                console.log(error)

                showToast(this.$t('code.updateDataError'), 2, this)
                this.showState = false
              })
          } else {
            this.showState = false
          }
        }).catch(() => {

        })
      } else {
        axios
          .post('', {
            query: `mutation{
            updateCategory(id:"${id}", input:{
              state: ${this.categoryStatus},
              isActive:${isActive},              
            }){
              category{
                id
                name
                state
              }
            }
          }
        `,
          })
          .then(res => {
            messageError(res, this)

            showToast(
              this.$t('code.cat', {
                data: res.data.data.updateCategory.category.name,
              }),
              1, this,
            )

            this.getCategoryInfo()

            this.showState = false
          })
          .catch(error => {
            console.log(error)

            showToast(this.$t('code.updateDataError'), 2, this)
            this.showState = false
          })
      }
    },
    updateCategoryFiles(event) {
      this.showFiles = true

      event.preventDefault()
      if (this.mediaOriginSelected === 'DIR') {
        const config = {
          timeout: 1000 * 60 * 30,
        }
        const data = new FormData()

        let objectVariables = []
        objectVariables = [
          { title: `imageUrl${this.language}`, value: this.imageCategoryUrlSelected, type: 'String' },
          { title: `imageMobileUrl${this.language}`, value: this.mobileImageCategoryURL, type: 'String' },
          { title: `backgroundUrl${this.language}`, value: this.backgroundCategoryURL, type: 'String' },
          { title: `alternativeImageUrl${this.language}`, value: this.altImageCategoryURL, type: 'String' },
          { title: `backgroundMobileUrl${this.language}`, value: this.backgroundMobileCategoryURL, type: 'String' },
          { title: `backgroundRootUrl${this.language}`, value: this.backgroundRootUrl, type: 'String' },
          { title: `backgroundRootMobileUrl${this.language}`, value: this.backgroundRootMobileUrl, type: 'String' },
          { title: `titleImageUrl${this.language}`, value: this.titleImageCategoryURL, type: 'String' },
          { title: `trailerUrl${this.language}`, value: this.trailerCategoryURL, type: 'String' },
          { title: `imagePodcastUrl${this.language}`, value: this.imagePodcastUrl, type: 'String' },
          { title: `sponsorImageUrl${this.language}`, value: this.sponsorImageUrl, type: 'String' },
        ]

        const variables = {}
        let mutation = 'mutation('
        let query = `{updateCategory(id:"${this.id}",input:{\n
          mediaLocation: DIR,\n`
        objectVariables.forEach(element => {
          variables[element.title] = element.value
          mutation += `$${element.title}:${element.type},\n`
          query += `${element.title}:$${element.title},\n`
        })
        mutation += ')'
        query += `}){
          category{
            id
            name            
          }
        }
      }`
        query = mutation + query

        data.append('query', query)
        data.append('variables', JSON.stringify(variables))
        // En caso de haber cambiado de tipo se eliminan imagenes del tipo anterior
        axios
          .post('', data, { headers }, config)
          .then(res => {
            messageError(res, this)

            showToast(
              this.$t('success'),
              1, this,
            )
            this.showFiles = false
            this.getCategoryInfo()
          })
          .catch(() => {
            this.showFiles = false

            showToast(this.$t('error'), 2, this)
          })
        this.showFiles = false
      } else {
        this.processBucketFiles()
      }
    },
    updateSegmentationCategory(event) {
      event.preventDefault()
      const { id } = this.$route.params

      axios
        .post('', {
          query: `mutation{
            updateCategory(id:"${id}", input:{
              targetWebActive: ${this.targetWebActive},
              targetHbbtvActive: ${this.targetHbbtvActive},
              targetMobileActive: ${this.targetMobileActive},
              targetSmarttvActive: ${this.targetSmarttvActive},
            }){
              category{
                id
                name
                state
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)
          showToast(
            this.$t('code.cat', {
              data: res.data.data.updateCategory.category.name,
            }),
            1, this,
          )
          this.getCategoryInfo()
        })
        .catch(error => {
          console.log(error)

          showToast(this.$t('code.updateDataError'), 2, this)
        })
    },
    processBucketFiles() {
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()

      // Borra antiguas imagenes de tipo url
      this.imageCategoryUrlSelected = ''
      this.mobileImageCategoryURL = ''
      this.backgroundCategoryURL = ''
      this.altImageCategoryURL = ''
      this.imagePodcastUrl = ''
      this.sponsorImageUrl = ''
      this.backgroundMobileCategoryURL = ''
      this.backgroundRootUrl = ''
      this.backgroundRootMobileUrl = ''
      this.titleImageCategoryURL = ''
      this.trailerCategoryURL = ''

      let objectVariables = []
      objectVariables = [
        { title: `imageUrl${this.language}`, value: this.imageCategoryUrlSelected, type: 'String' },
        { title: `imageMobileUrl${this.language}`, value: this.mobileImageCategoryURL, type: 'String' },
        { title: `backgroundUrl${this.language}`, value: this.backgroundCategoryURL, type: 'String' },
        { title: `alternativeImageUrl${this.language}`, value: this.altImageCategoryURL, type: 'String' },
        { title: `imagePodcastUrl${this.language}`, value: this.imagePodcastUrl, type: 'String' },
        { title: `sponsorImageUrl${this.language}`, value: this.sponsorImageUrl, type: 'String' },
        { title: `backgroundMobileUrl${this.language}`, value: this.backgroundMobileCategoryURL, type: 'String' },
        { title: `backgroundRootUrl${this.language}`, value: this.backgroundRootUrl, type: 'String' },
        { title: `backgroundRootMobileUrl${this.language}`, value: this.backgroundRootMobileUrl, type: 'String' },
        { title: `titleImageUrl${this.language}`, value: this.titleImageCategoryURL, type: 'String' },
        { title: `trailerUrl${this.language}`, value: this.trailerCategoryURL, type: 'String' },
      ]
      const variables = {}
      let mutation = 'mutation('
      let query = `{updateCategory(id:"${this.id}",input:{\n
          mediaLocation: BUK,\n`
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
          category{
            id
            name            
          }
        }
      }`
      query = mutation + query
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      data.append(`image_${camelToSnake(this.language)}`, this.imageCategory)
      data.append(`image_mobile_${camelToSnake(this.language)}`, this.mobileImageCategory)
      data.append(`background_${camelToSnake(this.language)}`, this.backgroundCategory)
      data.append(`background_root_${camelToSnake(this.language)}`, this.backgroundRoot)
      data.append(`background_root_mobile_${camelToSnake(this.language)}`, this.backgroundRootMobile)
      data.append(`alternative_image_${camelToSnake(this.language)}`, this.altImageCategory)
      data.append(`background_mobile_${camelToSnake(this.language)}`, this.backgroundMobileCategory)
      data.append(`title_image_${camelToSnake(this.language)}`, this.titleImageCategory)
      data.append(`trailer_${camelToSnake(this.language)}`, this.trailerCategory)
      data.append(`image_podcast_${camelToSnake(this.language)}`, this.imagePodcast)
      data.append(`sponsor_image_${camelToSnake(this.language)}`, this.sponsorImage)

      axios
        .post('', data, { headers }, config)
        .then(createResponse => {
          messageError(createResponse, this)

          showToast(this.$t('success'), 1, this)
          this.showFiles = false
          this.getCategoryInfo()
        })
        .catch(res => {
          showToast(this.$t('error'), 0, this)
          this.showFiles = false
          console.log(res)
        })
    },
    disabledFinalOption() {
      return (this.typeCategorySelected !== 'NOR' && this.typeCategorySelected !== 'AUL' && this.typeCategorySelected !== 'UNI' && this.typeCategorySelected !== 'COU' && this.typeCategorySelected !== 'MOD')
    },
    updateDesignCategory(event, type) {
      if (type === 0) this.showDesing = true
      else this.showDesingTit = true

      event.preventDefault()
      axios
        .post('', {
          variables:
            type === 0
              ? {
                id: this.id,
                card: this.cardSelected != null ? this.cardSelected.id : null,
                mobileCard:
                  this.cardMobileSelected != null
                    ? this.cardMobileSelected.id
                    : null,
                blurEffect: this.blurEffectStatus,
              }
              : {
                id: this.id,

                isTitle: this.isTitle,
                contentDesign: this.contentDesign,
              },
          query: `
          mutation( $id: ID!,${type === 0
    ? ' $card: ID,$mobileCard: ID, $blurEffect: Boolean'
    : '$isTitle: Boolean,$contentDesign: CategoryContentDesignInput'
} 
          ) {
            updateCategory(id: $id, input: 
              {
                ${type === 0
    ? ` card: $card,
                cardMobile: $mobileCard,
                isBackgroundBlur: $blurEffect`
    : ` isTitle: $isTitle,
                contentDesign: $contentDesign`
}                 
               
              }){
              category{
                id
                name
                isBackgroundBlur
                isTitle
                contentDesign
                card {
                  id
                  name
                }
                cardMobile {
                  id
                  name
                }
              }
            }
          }
        `,
        }, { headers })
        .then(res => {
          messageError(res, this)

          showToast(
            `Categoría ${res.data.data.updateCategory.category.name} actualizada correctamente`,
            1, this,
          )
          if (type === 0) this.showDesing = false
          else this.showDesingTit = false
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 2, this)
          if (type === 0) this.showDesing = false
          else this.showDesingTit = false
        })
    },

    hideModal() {
      this.$refs.modalCard.hide()
      this.$refs['slider-modal'].hide()
    },
    showModalCard(type) {
      this.$refs.modalCard.show()
      switch (type) {
        case 'cardTV':
          this.idSelectCard = this.cardSelected

          break
        case 'cardMobile':
          this.idSelectCard = this.cardMobileSelected

          break

        default:
          break
      }
      this.typeCard = type
    },
    SelectCardId(file) {
      switch (this.typeCard) {
        case 'cardTV':
          this.cardSelected = file

          break
        case 'cardMobile':
          this.cardMobileSelected = file
          break
        default:
          break
      }
      this.hideModal()
    },
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case `imageCategory${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.imageCategory = data
          else this.imageCategoryUrlSelected = data
          break
        case `backgroundCategory${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.backgroundCategory = data
          else this.backgroundCategoryURL = data
          break
        case `mobileImageCategory${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.mobileImageCategory = data
          else this.mobileImageCategoryURL = data
          break
        case `backgroundMobileCategory${this.language}`:

          if (this.mediaOriginSelected === 'BUK') this.backgroundMobileCategory = data
          else this.backgroundMobileCategoryURL = data
          break
        case `altImageCategory${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.altImageCategory = data
          else this.altImageCategoryURL = data
          break
        case `imagePodcast${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.imagePodcast = data
          else this.imagePodcastUrl = data
          break
        case `sponsorImage${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.sponsorImage = data
          else this.sponsorImageUrl = data
          break
        case `titleImageCategory${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.titleImageCategory = data
          else this.titleImageCategoryURL = data
          break
        case `trailerCategory${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.trailerCategory = data
          else this.trailerCategoryURL = data
          break
        case `backgroundRoot${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.backgroundRoot = data
          else this.backgroundRootUrl = data
          break
        case `backgroundRootMobile${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.backgroundRootMobile = data
          else this.backgroundRootMobileUrl = data
          break

        default:
          break
      }
    },

    updateSeo(toast = true) {
      this.showSeo = true
      let objectVariables = []
      objectVariables = [
        { title: `seoTitle${this.language}`, value: limitCharacter(this.seoTitle, 80), type: 'String' },
        { title: `seoDescription${this.language}`, value: limitCharacter(this.seoDescription, 180), type: 'String' },
        { title: `friendlyUrl${this.language}`, value: limitCharacter(this.friendlyUrlValue, 255), type: 'String' },
      ]
      const variables = {}
      let mutation = 'mutation('
      let query = `{updateCategory(id:"${this.id}",input:{\n
        `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
          category{
            id
            name
            
          }
        }
      }`
      query = mutation + query
      axios
        .post('', {
          variables,
          query,
        }, { headers })
        .then(res => {
          messageError(res, this)
          if (toast) {
            showToast(
              this.$t('success'),
              1, this,
            )
          }
          this.showSeo = false
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.showSeo = false
        })
    },
    genre() {
      axios
        .post('', {
          query: `
            query{              
            allGenre(client:"${this.userData.profile.client.id}") {
              edges {
                node {
                  id
                  name
                  description
                  isActive                
                }
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allGenre.edges.forEach(element => {
            if (element.node.isActive) {
              this.allGenre.push({
                value: element.node.id,
                text: element.node.name,
              })
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    clasification() {
      const { id } = this.userData.profile.client
      axios
        .post('', {
          query: `
          query{  
            allClasification {
              edges {
                node {
                  id
                  type
                  initial
                  description
                  color
                   clasificationsClient {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }               
                  
                }
              }
            }
            }
        `,
        })
        .then(result => {
          messageError(result, this)
          result.data.data.allClasification.edges.forEach(element => {
            if (element.node.clasificationsClient.edges[0] != null) {
              element.node.clasificationsClient.edges.forEach(a => {
                if (a.node.id === id) {
                  this.allClasification.push(element)
                }
              })
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    changeFinal() {
      switch (this.typeCategorySelected) {
        case 'NOR':
        case 'COU':
        case 'UNI':
        case 'MOD':
        case 'AUL':
          break
        case 'SER':
        case 'PRO':
        case 'FET':
        case 'LIN':
        case 'FIL':
        case 'FAC':
          this.finalCategory = false
          break
        case 'FAV':
        case 'POD':
        case 'DIR':
        case 'COW':
        case 'LAS':
        case 'NEW':
        case 'MOS':
        case 'FOY':
        case 'SUM':
        case 'CLI':
          this.finalCategory = true
          break
        default:
          break
      }
    },
    videoMaker() {
      if (this.mediaOriginSelected === 'DIR') {
        this.video = videojs('demoDIR')
      } else {
        this.video = videojs('demoBUK')
      }

      this.video.on('ready', function () {
        try {
          this.video.vhs = null
          // eslint-disable-next-line no-empty
        } catch (error) { }
      })
    },
    deleteImage(type) {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        // cancelButtonText: "Indicar url",
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.showFiles = true

            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            if (this.mediaOriginSelected === 'BUK') {
              const query = `
                  mutation {
                    updateCategory(id: "${this.id}", input: 
                    {
                      mediaLocation: BUK
                    }) 
                    {
                      category {
                        image
                        imageMobile
                        background
                        titleImage
                        trailer
                      }
                    }
                  }
                `
              data.append('query', query)

              switch (type) {
                case `imageCategory${this.language}`:
                  this.imageCategorySelected = null
                  data.append('deleteFile', `image_${camelToSnake(this.language)}`)
                  break
                case `mobileImageCategory${this.language}`:
                  this.mobileImageCategorySelected = null
                  data.append('deleteFile', `image_mobile_${camelToSnake(this.language)}`)

                  break

                case `altImageCategory${this.language}`:
                  this.altImageCategorySelected = null
                  data.append('deleteFile', `alternative_image_${camelToSnake(this.language)}`)
                  break
                case `imagePodcast${this.language}`:
                  this.imagePodcastSelected = null
                  data.append('deleteFile', `image_podcast_${camelToSnake(this.language)}`)
                  break
                case `sponsorImage${this.language}`:
                  this.sponsorImageSelected = null
                  data.append('deleteFile', `sponsor_image_${camelToSnake(this.language)}`)
                  break
                case `backgroundCategory${this.language}`:
                  this.backgroundCategorySelected = null
                  data.append('deleteFile', `background_${camelToSnake(this.language)}`)

                  break
                case `titleImageCategory${this.language}`:
                  this.titleImageCategorySelected = null
                  data.append('deleteFile', `title_image_${camelToSnake(this.language)}`)

                  break
                case `backgroundMobileCategory${this.language}`:
                  this.backgroundMobileCategorySelected = null
                  data.append('deleteFile', `background_mobile_${camelToSnake(this.language)}`)

                  break
                case `backgroundRootMobile${this.language}`:
                  this.backgroundRootMobileSelected = null
                  data.append('deleteFile', `background_root_mobile_${camelToSnake(this.language)}`)

                  break
                case `backgroundRoot${this.language}`:
                  this.backgroundRootSelected = null
                  data.append('deleteFile', `background_root_${camelToSnake(this.language)}`)

                  break
                case `trailerCategory${this.language}`:
                  this.trailerCategorySelected = null
                  data.append('deleteFile', `trailer_${camelToSnake(this.language)}`)

                  break

                default:
                  break
              }

              axios
                .post('', data, { headers }, config)
                .then(createResponse => {
                  messageError(createResponse, this)

                  showToast(this.$t('success'), 1, this)
                  this.showFiles = false
                  this.getCategoryInfo()
                })
                .catch(res => {
                  showToast(this.$t('error'), 0, this)
                  this.showFiles = false
                  console.log(res)
                })
            } else {
              let objectVariables = []

              switch (type) {
                case 'imageCategory':
                  this.imageCategoryUrlSelected = ''
                  objectVariables = [
                    { title: `imageUrl${this.language}`, value: this.imageCategoryUrlSelected, type: 'String' },
                  ]
                  break
                case 'mobileImageCategory':
                  this.mobileImageCategoryURL = ''

                  objectVariables = [
                    { title: `imageMobileUrl${this.language}`, value: this.mobileImageCategoryURL, type: 'String' },
                  ]
                  break

                case 'altImageCategory':
                  this.altImageCategoryURL = ''

                  objectVariables = [
                    { title: `alternativeImageUrl${this.language}`, value: this.altImageCategoryURL, type: 'String' },
                  ]
                  break
                case 'imagePodcast':
                  this.imagePodcastUrl = ''

                  objectVariables = [
                    { title: `imagePodcastUrl${this.language}`, value: this.imagePodcastUrl, type: 'String' },
                  ]
                  break
                case 'sponsorImage':
                  this.sponsorImageUrl = ''

                  objectVariables = [
                    { title: `sponsorImageUrl${this.language}`, value: this.sponsorImageUrl, type: 'String' },
                  ]
                  break
                case 'backgroundCategory':
                  this.backgroundCategoryURL = ''

                  objectVariables = [
                    { title: `backgroundUrl${this.language}`, value: this.backgroundCategoryURL, type: 'String' },
                  ]

                  break
                case 'titleImageCategory':
                  this.titleImageCategoryURL = ''

                  objectVariables = [
                    { title: `titleImageUrl${this.language}`, value: this.titleImageCategoryURL, type: 'String' },
                  ]

                  break
                case 'backgroundMobileCategory':
                  this.backgroundMobileCategoryURL = ''

                  objectVariables = [
                    { title: `backgroundMobileUrl${this.language}`, value: this.backgroundMobileCategoryURL, type: 'String' },
                  ]

                  break
                case 'backgroundRootMobile':
                  this.backgroundRootMobileUrl = ''

                  objectVariables = [
                    { title: `backgroundRootMobileUrl${this.language}`, value: this.backgroundRootMobileUrl, type: 'String' },
                  ]

                  break
                case 'backgroundRoot':
                  this.backgroundRootUrl = ''

                  objectVariables = [
                    { title: `backgroundRootUrl${this.language}`, value: this.backgroundRootUrl, type: 'String' },
                  ]

                  break
                case 'trailerCategory':
                  this.trailerCategoryURL = ''

                  objectVariables = [
                    { title: `trailerUrl${this.language}`, value: this.trailerCategoryURL, type: 'String' },
                  ]
                  break

                default:
                  break
              }

              const variables = {}
              let mutation = 'mutation('
              let query = `{updateCategory(id:"${this.id}",input:{\n
                mediaLocation: DIR,\n`
              objectVariables.forEach(element => {
                variables[element.title] = element.value
                mutation += `$${element.title}:${element.type},\n`
                query += `${element.title}:$${element.title},\n`
              })
              mutation += ')'
              query += `}){
                category{
                  id
                  name            
                }
              }
            }`
              query = mutation + query

              data.append('query', query)
              data.append('variables', JSON.stringify(variables))
              // En caso de haber cambiado de tipo se eliminan imagenes del tipo anterior
              axios
                .post('', data, { headers }, config)
                .then(res => {
                  messageError(res, this)

                  showToast(
                    this.$t('success'),
                    1, this,
                  )
                  this.showFiles = false
                  // this.getCategoryInfo()
                })
                .catch(() => {
                  this.showFiles = false

                  showToast(this.$t('error'), 2, this)
                })
            }
          }
        })
        .catch(() => { })
    },
    getCategories() {
      axios
        .post('', {
          query: `
            query{
                NOR: allCategories(name:"${this.search}",type:"NOR",isFinal:true,client:"${this.userData.profile.client.id}"
                ${this.isSponsor ? `,filter_Sponsor:"${this.userData.id}"` : ''} ){
              edges {
                  node {
                  id
                  name
                  type
                }
              }
            }
                DIR: allCategories(name:"${this.search}",type:"DIR",isFinal:true,client:"${this.userData.profile.client.id}"
                ${this.isSponsor ? `,filter_Sponsor:"${this.userData.id}"` : ''} ){
                  edges {
                    node {
                  id
                  name
                  type
                }
              }
            }
            POD: allCategories(name:"${this.search}",type:"POD",isFinal:true,client:"${this.userData.profile.client.id}"){
              edges {
                node {
              id
              name
              type
                }
              }
            }
            UNI: allCategories(name:"${this.search}",type:"UNI",isFinal:true,client:"${this.userData.profile.client.id}"){
                  edges {
                    node {
                  id
                  name
                  type
                }
              }
            }
          }
          `,
        })
        .then(response => {
          messageError(response, this)

          const arr2 = [
            ...response.data.data.NOR.edges,
            ...response.data.data.DIR.edges,
            ...response.data.data.POD.edges,
            ...response.data.data.UNI.edges,
          ]
          const array = []
          for (let i = 0; i < arr2.length; i += 1) {
            let bool = false
            for (let j = 0; j < this.categorySelected.length; j += 1) {
              if (this.categorySelected[j].node.id === arr2[i].node.id) {
                bool = true
              }
            }

            if (!bool) {
              array.push(arr2[i])
            }
          }

          this.allCats = array.slice()
          this.searchByName()
        })
    },
    emptySlider() {
      this.sliderId = null
      this.sliderName = null
    },
    modalTags() {
      if (this.selectedTag.length > this.idTags.length) {
        let id = ''
        axios
          .post('', {
            query: `
          {
            allTags(name:"${this.selectedTag[this.selectedTag.length - 1]}") {
              edges {
                node {
                  id
                  name
                  createdAt

                }
              }
            }
          }
        `,
          })
          .then(result => {
            messageError(result, this)

            let crear = true
            result.data.data.allTags.edges.forEach(element => {
              if (
                element.node.name.toLowerCase()
                === this.selectedTag[this.selectedTag.length - 1].toLowerCase()
              ) {
                id = element.node.id
                crear = false
              }
            })

            if (crear) {
              /* AQUÍ CREA EL TAG */
              axios
                .post('', {
                  query: `
              mutation{
                createTag(input:{name:"${this.selectedTag[this.selectedTag.length - 1]
}"}){
                    tag{
                        id
                        name
                    }
                }
            }
            `,
                }, { headers })
                .then(r => {
                  messageError(r, this)

                  this.idTags.push(r.data.data.createTag.tag.id)
                })
                .catch(() => { })
            } else {
              this.idTags.push(id)
            }
          })
          .catch(() => { })
      } else {
        this.originalTags.forEach((a, index) => {
          let quitar = true
          this.selectedTag.forEach(element => {
            if (element.toLowerCase() === a.toLowerCase()) {
              quitar = false
            }
          })
          if (quitar) {
            this.idTags.splice(index, 1)
            this.originalTags.splice(index, 1)
          }
        })
      }
    },
    getTags() {
      axios
        .post('', {
          query: `
          {
            allTags {
              edges {
                node {
                  id
                  name
                  createdAt

                }
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allTags.edges.forEach(element => {
            this.optionTag.push({
              title: element.node.name,
              value: element.node.id,
            })
          })
        })
        .catch(() => { })
    },
    newTag(data) {
      this.hideModal()
      this.optionTag.push({
        title: data.name,
        value: data.id,
      })
      this.selectedTag.push({
        title: data.name,
        value: data.id,
      })
    },
    QuitarCat() {
      if (this.checkRemove.length !== 0) {
        this.allCats = [...this.checkRemove, ...this.allCats]
        this.checkRemove.forEach(element => {
          const idx = this.categorySelected.indexOf(element)
          if (idx !== -1) {
            this.categorySelected.splice(idx, 1)
          }
        })
        this.checkRemove = []
        this.searchByName()
        this.getCategoriesSelected()
      } else {
        this.allCats = [...this.resultCategorySelected, ...this.allCats]

        this.resultCategorySelected.forEach(element => {
          const idx = this.categorySelected.indexOf(element)
          if (idx !== -1) {
            this.categorySelected.splice(idx, 1)
          }
        })
        this.resultCategorySelected = []
        this.searchByName()
      }
    },
    AddCat() {
      if (this.checkResults.length !== 0) {
        this.categorySelected = [...this.categorySelected, ...this.checkResults]
        this.getCategoriesSelected()
        this.checkResults.forEach(element => {
          // document.getElementById(element.node.id + '_res').classList.toggle("active");
          const idx = this.resCategories.indexOf(element)
          if (idx !== -1) {
            this.resCategories.splice(idx, 1)
          }
          this.allCats = this.allCats.filter(e => element.node.id !== e.node.id)
        })
        this.checkResults = []
        this.searchByName()
      } else {
        this.categorySelected = [...this.categorySelected, ...this.resCategories]
        this.getCategoriesSelected()

        this.resCategories.forEach(element => {
          this.allCats = this.allCats.filter(e => element.node.id !== e.node.id)
        })

        this.resCategories = []

        this.searchByName()
      }
    },
    goBack() {
      window.history.back()
    },
    updateCategoryRestriction(event) {
      event.preventDefault()
      axios
        .post('', {
          query: `
         mutation{
          updateCategory(id:"${this.id
}",input:{lockedCategories: [${this.getIdArray(
  this.categorySelected,
)}]}){
            category {
              id
              name
            }
          }
        }
        `,
        }, { headers })
        .then(result => {
          messageError(result, this)
          showToast(this.$t('success'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },

    getIdArray(array) {
      const res = []
      for (let i = 0; i < array.length; i += 1) {
        res.push(array[i].node.id)
      }
      return res
    },
    getSlider() {
      const query = `{
      slider(id:"${this.sliderId}") {
        edges {
          node {
            id
            name
            slide {
              edges {
                node {
                  id
                  slideType
                  name
                  imageUrl
                  isActive
                }
              }
            }
          }
        }
      }
    }`
      axios
        .post('', {
          query,
        }).then(result => {
          messageError(result, this)

          this.slider = result.data.data.slider.edges
        }).catch(() => {

        })
    },
    searchByName() {
      this.resCategories = this.allCats.filter(item => item.node.name.toLowerCase().includes(this.search.toLowerCase()))
      this.resCategories.sort((firstParams, secondParams) => firstParams.node.name.localeCompare(secondParams.node.name))
    },
    buildImageUrl(folder) {
      return folder.imageUrl == null || folder.imageUrl.length === 0
        ? noCover
        : folder.imageUrl
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "vue-context/src/sass/vue-context";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#editarCategoria #add-new-category-sidebar {
  #editarCategoria .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#editarCategoria .base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#editarCategoria .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#editarCategoria .placeholder:hover {
  background: #e0e0e0;
}

#editarCategoria .file-input {
  display: none;
}

#editarCategoria .final-info {
  // display: flex;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}
</style>
